import initPreloader from './modules/preolader';
import initNavigation from './modules/navigation';
import initAccordion from './modules/accordion';
import initGallery from './modules/gallery';
import initTextSlider from './modules/text-slider';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  initPreloader();
  initAccordion();
  initGallery();
  initTextSlider();
  initNavigation();
});
