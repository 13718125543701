export default function initGallery() {
    var tabs = document.querySelectorAll('.gallery__tabs-item');
    var galleryImagesBlocks = document.querySelectorAll('.gallery__images');

    tabs.forEach(function(tab, index) {
        tab.addEventListener('click', function() {
            // Видаляємо клас active з усіх вкладок і блоків зображень
            tabs.forEach(function(otherTab) {
                otherTab.classList.remove('active');
            });
            galleryImagesBlocks.forEach(function(imagesBlock) {
                imagesBlock.classList.remove('active');
            });

            // Додаємо клас active до вибраної вкладки та відповідного блоку зображень
            tab.classList.add('active');
            if (galleryImagesBlocks[index]) {
                galleryImagesBlocks[index].classList.add('active');
            }
        });
    });

}
  