import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initTextSlider() {
    const communitySwiper = document.querySelectorAll('.text-video');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.text-video__swiper');

      var swiper = new Swiper( slider , {
        modules: [Navigation],
        loop: true,
        navigation: {
            nextEl: el.querySelector(".text-video__swiper-next"),
            prevEl: el.querySelector(".text-video__swiper-prev"),
        }
      });

    var video = el.querySelector('#videoPlayer');
    var playPauseBtn = el.querySelector('#playPauseBtn');

    function showButton() {
        playPauseBtn.style.display = 'block';
    }

    function hideButton() {
        playPauseBtn.style.display = 'none';
    }

    playPauseBtn.addEventListener('click', function() {
        if (video.paused || video.ended) {
            video.play();
            hideButton();
        }
    });

    video.addEventListener('click', function() {
        if (!video.paused && !video.ended) {
            video.pause();
            showButton();
        }
    });

    video.controls = false;

    video.addEventListener('ended', showButton);
    video.addEventListener('pause', showButton);

    });

//     var dateInputs = document.querySelectorAll('.datacustom');

//     dateInputs.forEach(function(input) {
//     input.addEventListener('focus', function() {
//         this.type = 'date';
//         if (this.type === 'date') {
//             this.click();
//         }
//     });
//     input.addEventListener('blur', function() {
//         this.type = 'text';
//     });
// });
}
